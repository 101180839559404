import React, { useState, useEffect } from "react";
import {
  useI18next,
  useTranslation,
  I18nextContext,
} from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import { Router } from "@reach/router";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Grid from "@mui/material/Grid";
import { getFromApi, getFromApiUnAuth } from "../lib/api";
import { isBrowser } from "../lib/config";
import CircularProgress from "@mui/material/CircularProgress";
import TwitterIcon from "@mui/icons-material/Twitter";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { checkPro, getToken } from "../lib/user.auth";

const App = () => {
  const { t } = useTranslation();
  const context = React.useContext(I18nextContext);
  return (
    <Layout>
      <Seo title="Facebook" />
      <h1>Facebook insights</h1>
      <Grid>
        <Router>
          <Child path={`/app/facebook/:facename`} />
          <Input path={`/app/facebook`} />
        </Router>
      </Grid>
    </Layout>
  );
};

export default App;

const Input = () => {
  return (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      style={{ marginTop: "40px" }}
    >
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="name"
          label="Facebook username or pagename"
          name="name"
          onKeyPress={(e) => {
            if (e.keyCode === 13 || e.which === 13) {
              document.location.href =
                "/app/facebook/" + document.getElementById("name").value;
            }
          }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={(e) => {
            {
              document.location.href =
                "/app/facebook/" + document.getElementById("name").value;
            }
          }}
        >
          Check profile
        </Button>
        <br />
        <br />
        <h4>Pattern</h4>
        <small>
          facebook.com/<strong>MyFollower</strong>
          &nbsp;&nbsp;&nbsp; 👈 pagename
        </small>
        <br />
        <small>
          facebook.com/<strong>mitchobrian</strong>
          &nbsp;&nbsp;&nbsp; 👈 username
        </small>
        {!checkPro() && (
          <div>
            <br />
            <hr />
            <table
              style={{ border: 0 }}
              align="center"
              border="0"
              cellPadding={0}
              cellSpacing={0}
            >
              <tbody>
                <tr style={{ border: 0 }}>
                  <td colSpan={2} style={{ border: 0 }}>
                    <a
                      href={`//tracking.inboxads.com/api/href/?host=my-follower.com&z=zjPWVy55lbn1nGea&u=none@my-follower.com&t=${Math.round(
                        new Date() / 1000
                      )}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={`//tracking.inboxads.com/api/src/?host=my-follower.com&z=zjPWVy55lbn1nGea&u=none@my-follower.com&t=${Math.round(
                          new Date() / 1000
                        )}`}
                        useMap={"#maimapchoice_iA7wQC4I9c4i9br1"}
                      />
                    </a>
                    <map name="maimapchoice_iA7wQC4I9c4i9br1">
                      <area
                        shape="rect"
                        coords="0,0,300,600"
                        href={`//tracking.inboxads.com/api/href/?host=my-follower.com&z=zjPWVy55lbn1nGea&u=none@my-follower.com&t=${Math.round(
                          new Date() / 1000
                        )}`}
                        target="_blank"
                        alt="a0"
                      />
                    </map>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <p>
          Get insights of <strong>any</strong> facebook account!
          <br />
          <br />
          Do you know the services from commentpicker.com, findmyfbid.in or
          lookup-id.com? <b>Throw it in the bin!</b> This serive is born and
          will replace the rest!
          <br />
          <br />
          Find yours or <b>ANY</b> Facebook ID! This finder is an online tool to
          quickly find the numeric Facebook ID, the profile picture and the real
          name of any account.
          <br />
          <br />
          It is free and works without login. You only need to enter the
          Facebook username or the page name shown in the URL and{" "}
          <strong>RUN</strong>.
        </p>
      </Grid>
    </Grid>
  );
};

const Child = (props) => {
  const [newname, setNewName] = useState("");
  const [picture, setPicture] = useState("");
  const [chart, setChart] = useState("");
  const [facebookName, setName] = useState("");
  const [id, setId] = useState(null);
  const [count_facebook, setFacebookCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const getFacebook = async () => {
    let result = null;
    if (getToken())
      result = await getFromApi("/1.0/get/facebook/" + props.facename);
    else result = await getFromApiUnAuth("/1.0/get/facebook/" + props.facename);
    if (result.code === 200 && result.success) {
      setNewName(result.newname);
      setPicture(result.picture);
      setFacebookCount(result.likes);
      setChart(result.chart);
      setId(result.id);
      setName(result.name);
    }
  };

  const loader = async () => {
    await getFacebook();
    setLoading(false);
  };

  useEffect(() => {
    // first check url on new token
    if (process.env.NODE_ENV === "development") {
      console.log("wird geladen...");
    }
    if (isBrowser() && loading && newname === "") {
      setNewName("loading...");
      loader();
    }
  }, []);

  return !loading ? (
    <div>
      {facebookName ? (
        <>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            style={{ marginTop: "40px" }}
          >
            <Grid item xs={12} sm={6}>
              {picture && (
                <>
                  <div
                    style={{
                      backgroundImage: `url(${atob(picture)})`,
                      width: 126,
                      height: 126,
                      backgroundSize: "100% 100%",
                    }}
                    className="circularImage"
                    src={picture}
                    alt={newname}
                    height="120"
                  />
                  <br />
                </>
              )}
              Real name: <b>{newname ? newname : facebookName}</b>
              <br />
              <br />
              Fans/friends: <b>{count_facebook.toLocaleString("de-DE")}</b>
              <br />
              <br />
              Facebook ID: <b>{id}</b>
              <br />
              <br />
              <a
                href={`https://www.facebook.com/${facebookName}`}
                target="_blank"
                title={`${facebookName}`}
              >{`facebook.com/${facebookName}`}</a>
            </Grid>
            <Grid item xs={12} sm={6}>
              <a
                href={`https://x.com/intent/tweet?original_referer=https://my-follower.com&text=Get facebook insights easily with %23MyFollower check out &tw_p=tweetbutton&url=${encodeURI(
                  document.location.href
                )} @MyFollowerCom`}
                title="x formerly twitter"
                target="_blank"
              >
                <img
                  src="https://palmomedia.de/twitter-x-logo.png"
                  height="30"
                  alt="X"
                  style={{ marginBottom: -5 }}
                />{" "}
                tweet this result!
              </a>
              <br />
              {chart && (
                <>
                  <br />
                  <img
                    className="circularImageLeft"
                    style={{ margin: 10 }}
                    src={chart}
                    alt={newname}
                  />
                  <br />
                </>
              )}
              <br />
              <h3>
                Do you need an <strong>API</strong>?
              </h3>
              Contact us! We're building strong APIs for ANY use case. Visit us
              at{" "}
              <a
                href="https://palmomedia.de"
                target="_blank"
                title="palmomedia API"
              >
                palmomedia
              </a>
              .
              {!checkPro() && (
                <div>
                  <br />
                  <hr />
                  <table
                    style={{ border: 0 }}
                    align="center"
                    border="0"
                    cellPadding={0}
                    cellSpacing={0}
                  >
                    <tbody>
                      <tr style={{ border: 0 }}>
                        <td colSpan={2} style={{ border: 0 }}>
                          <a
                            href={`//tracking.inboxads.com/api/href/?host=my-follower.com&z=zjPWVy55lbn1nGea&u=none@my-follower.com&t=${Math.round(
                              new Date() / 1000
                            )}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={`//tracking.inboxads.com/api/src/?host=my-follower.com&z=zjPWVy55lbn1nGea&u=none@my-follower.com&t=${Math.round(
                                new Date() / 1000
                              )}`}
                              useMap={"#maimapchoice_iA7wQC4I9c4i9br1"}
                            />
                          </a>
                          <map name="maimapchoice_iA7wQC4I9c4i9br1">
                            <area
                              shape="rect"
                              coords="0,0,300,600"
                              href={`//tracking.inboxads.com/api/href/?host=my-follower.com&z=zjPWVy55lbn1nGea&u=none@my-follower.com&t=${Math.round(
                                new Date() / 1000
                              )}`}
                              target="_blank"
                              alt="a0"
                            />
                          </map>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Alert severity="error">No user found!</Alert>
          <br />
          Unfortunately, sometimes we can't get the correct Facebook account or
          the ID with our tool. You can get the Facebook ID yourself by going to
          the profile, right click with your mouse and click "View page source",
          then search for the value "entity_id" or "userID".
          <br />
          <br />
          Do you have trouble or problems please let us know!
          <br />
          <br />
          If you like to check YOUR account, run to facebook by hitten{" "}
          <a href="https://www.facebook.com/profile.php" target="_blank">
            this links
          </a>
          .
          <br />
        </>
      )}
      <br />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        onClick={(e) => {
          {
            document.location.href = "/app/facebook/";
          }
        }}
      >
        Check next user
      </Button>
    </div>
  ) : (
    <div>
      We're looking for {props.facename}
      <br />
      <br />
      <CircularProgress />
    </div>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
